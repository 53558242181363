import { LabelValue } from '@core/types/label-value.type';
import { IDateRange } from '@components/selects/date-range-picker/date-range-picker.component';

export enum DaysOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum CronFrequency {
  Never = 0,
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
  Everyday = 8,
}

export class DateUtil {
  public static defaultDateFnsFormat = 'yyyy-MM-dd';
  public static dateFnsTimeFormat = 'HH:mm:ss';
  public static dateFnsDateTimeFormat = this.defaultDateFnsFormat + ' ' + this.dateFnsTimeFormat;
  public static dateFnsDateAtTimeFormat = this.defaultDateFnsFormat + " 'at' " + this.dateFnsTimeFormat;
  public static dateFnsDateTimeWithTimezoneFormat = this.dateFnsDateTimeFormat + ' OOOO';
  public static dateFnsDateAtTimeWithTimezoneFormat =
    this.defaultDateFnsFormat + " 'at' " + this.dateFnsTimeFormat + ' OOOO';
  public static readonly Days = {};

  public static getDaysOfWeek(): LabelValue<DaysOfWeek>[] {
    return [
      { label: 'Sunday', value: DaysOfWeek.Sunday },
      { label: 'Monday', value: DaysOfWeek.Monday },
      { label: 'Tuesday', value: DaysOfWeek.Tuesday },
      { label: 'Wednesday', value: DaysOfWeek.Wednesday },
      { label: 'Thursday', value: DaysOfWeek.Thursday },
      { label: 'Friday', value: DaysOfWeek.Friday },
      { label: 'Saturday', value: DaysOfWeek.Saturday },
    ];
  }

  public static getCronFrequency(): LabelValue<number>[] {
    return [
      { label: 'Never', value: CronFrequency.Never },
      { label: 'Sunday', value: CronFrequency.Sunday },
      { label: 'Monday', value: CronFrequency.Monday },
      { label: 'Tuesday', value: CronFrequency.Tuesday },
      { label: 'Wednesday', value: CronFrequency.Wednesday },
      { label: 'Thursday', value: CronFrequency.Thursday },
      { label: 'Friday', value: CronFrequency.Friday },
      { label: 'Saturday', value: CronFrequency.Saturday },
      { label: 'Everyday', value: CronFrequency.Saturday },
    ];
  }

  public static getTimesOfDay(): LabelValue<string>[] {
    return [
      { label: '00:00 AM', value: '0' },
      { label: '01:00 AM', value: '1' },
      { label: '02:00 AM', value: '2' },
      { label: '03:00 AM', value: '3' },
      { label: '04:00 AM', value: '4' },
      { label: '05:00 AM', value: '5' },
      { label: '06:00 AM', value: '6' },
      { label: '07:00 AM', value: '7' },
      { label: '08:00 AM', value: '8' },
      { label: '09:00 AM', value: '9' },
      { label: '10:00 AM', value: '10' },
      { label: '11:00 AM', value: '11' },
      { label: '12:00 PM', value: '12' },
      { label: '01:00 PM', value: '13' },
      { label: '02:00 PM', value: '14' },
      { label: '03:00 PM', value: '15' },
      { label: '04:00 PM', value: '16' },
      { label: '05:00 PM', value: '17' },
      { label: '06:00 PM', value: '18' },
      { label: '07:00 PM', value: '19' },
      { label: '08:00 PM', value: '20' },
      { label: '09:00 PM', value: '21' },
      { label: '10:00 PM', value: '22' },
      { label: '11:00 PM', value: '23' },
    ];
  }

  /**
   * Date range string in the format of: YYYY-mm-DD,YYYY-mm-DD
   * Ex: 2020-12-31,2021-12-31
   * @param dateRangeString
   */
  public static dateRangeStringToObject(dateRangeString: string): IDateRange {
    const dateRangeSplit = dateRangeString.split(',');
    const dateFrom = DateUtil.dateStringToObject(dateRangeSplit[0]);
    const dateTo = DateUtil.dateStringToObject(dateRangeSplit[1]);
    return {
      from: dateFrom,
      to: dateTo,
    };
  }

  /**
   * Date string into expected date object (without messed up local).
   * Format is: YYYY-mm-DD
   * Ex: 2020-12-31
   * @param dateStringAsYearMonthDate
   */
  public static dateStringToObject(dateStringAsYearMonthDate: string): Date {
    const dateSplit = dateStringAsYearMonthDate.split('-').map((n) => Number(n));
    return new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
  }
}
